import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
/// WARNING : 3 routes require Auth : /database, /database/:model, /database/new_model
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('../views/DocumentationView.vue')
  },
  {
    path: '/models',
    name: 'models',
    component: () => import('../views/ModelView.vue')
  },
  {
    path: '/info_computation',
    name: 'info_computation',
    component: () => import('../views/InfoComputationView.vue')
  },
  {
    path: '/api',
    name: 'api',
    component: () => import('../views/APIView.vue')
  },
  {
    path: '/app/:name',
    name: 'appSingle',
    component: () => import('../views/AppSingle.vue'),
  },
  {
    path: '/database',
    name: 'database',
    component: () => import('../views/DatabaseView.vue'),
    /*meta: {
      requiresAuth: true
    }*/
  },
   //dynamic for models:
   {
    path: '/models/:name_model',
    name: 'info_model',
    component: () => import('../views/ModelInfoUserView.vue'),
  },
  {
    path: '/database/:name_model',
    name: 'db_model',
    component: () => import('../views/ModelDBView.vue'),
    /*meta: {
      requiresAuth: true
    }*/
  },
  {
    path: '/database/new_model',
    name: 'db_new_model',
    component: () => import('../views/ModelDBFormAddNewView.vue'),
    /*meta: {
      requiresAuth: true
    }*/
  },
]


const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})
//router.beforeEach((to, from) => {
//  if (to.meta.requiresAuth && !store.state.getters['user/isAdmin']) {
//    return {
//      name: 'home',
//    }
//  }
//})

export default router
