<template>
  <div class="events container ">
    
    <div class="columns is-multiline is-centered">
      <div
        v-for="event in events"
        :event="event"
        :key="event.id"
        class="column is-one-quarter"
      >
        <router-link :to="'/app/' + event.route">
          <EventCard :event="event" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import EventCard from '@/components/EventCard';

  export default {
    name: 'EventsList',
    components: {
      EventCard,
    },
    data() {
      return {
        event: {},
        events: [
          {
            id: 1,
            name: 'Load',
            route: 'viewer',
            logo: 'assets/logo_load.png',
            description:'Visualize loading models',
            //featuredImage: "https://www.openstreetmap.org/assets/osm_logo_256-ed028f90468224a272961c380ecee0cfb73b8048b34f4b4b204b7f0d1097875d.png",
          },
          {
            id: 2,
            name: 'Deformation',
            logo :"assets/logo_deformation.png",
            route: 'computation',
            description:'Compute load-induced deformation',
          },
        ],
      };
    },
  };
</script>
<style lang="scss" scoped>
  .events {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    //background-image: url("../assets/bg_aliedocs.svg");
    //background-size: cover;
  }
</style>