<template>
  <div id="app">
    <Nav />
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
  import { useAuth0, AuthState } from "./utils/useAuth0";
  import Nav from './components/Nav.vue';


  export default {
    name: 'app',
    components: {
      Nav,
    },
  };
</script>
<style lang="scss">
 
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
 
</style>
