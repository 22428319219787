<template>

<div >
	
    <div v-if="!service || !service.getEmail()" class="user_profile">
      <img src="../assets/user.png" alt="Avatar" class="avatar">
        <a @click="service.login()" > | <u>Login</u></a>
    </div>

    <div v-else class="user_profile connected">
        <p>{{ service.getEmail()}}</p>
        <a @click="service.logout()"> | <u>Logout</u></a>
    </div>
	
</div>

  

</template>

<script >
/**
import {AuthService} from 'formater-auth-service-js'
AuthService.setRedirectUri(process.env.VUE_APP_redirect_uri)

// import { useAuth0, AuthState } from "../utils/useAuth0";
// const { login, logout, initAuth } = useAuth0(AuthState);
//import { useAuth0 } from "@auth0/auth0-vue";

// initAuth();

export default {
	data () {
		return {
			service: null
	    }
	},
	computed: {
		email () {
			return this.$store.getters['user/email']
		}
	},
	mounted () {
		console.log(process.env.VUE_APP_KEYCLOAK_auth)
		if (process.env.VUE_APP_KEYCLOAK_auth) {
			this.service = new AuthService('aliedocs', {
			    clientId: 'aliedocs-api',
			    method: 'apache',
			    authUrl: process.env.VUE_APP_KEYCLOAK_auth,
			    refreshUrl: process.env.VUE_APP_KEYCLOAK_userinfo
//	 		    sso: 'formater',
			  })
		} else {
			this.service = new AuthService('aliedocs', {
			    keycloakUrl: 'https://sso.aeris-data.fr/auth/realms/formater',
			    clientId: 'aliedocs-vjs',
			    method: 'public'
	        })
		}
		this.service.add()
		var self = this
		this.service.on('authenticated', function (user, serv) {
			 console.log('auth')
			 self.$store.commit('user/setUser', user)
			
		})
		this.service.on('logout', function () {
			self.$store.commit('user/setUser', null)
			if(self.$route.path.substring(0, 9) === '/database') {
				self.$router.push({name: 'home'})
			}
		})
	},
    destroyed () {
	    // On supprime tous les services du DOM 
	    this.service.remove()
	    this.service = null
	}
}*/
</script>

<style lang="scss" scoped>
    .avatar{
      border-radius: 50%;
      width:40px;
      height:40px;
      margin:5px
     
    }
    .user_profile{
      display: flex;
      align-items: center;
      justify-content: center
    }
    .user_profile.connected p {
      padding-right:5px;
    }
    .user_profile.connected p,
    .user_profile.connected a {
      margin-top:15px;
    }

</style>