<template>
  <div class="event-card">
    <div class="card">
      <div class="card-content" >
        <h2 class="is-size-4 has-text-weight-bold">{{ event.name }}</h2>
        <img :id="get_logo_id()" :src="event.logo" alt="logo" height="110" width="110" > 
        <span>{{ event.description }}</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    props: ['event'], 
    methods: {
     

      get_logo_id (){
        return "l_"+this.event.name
      },

      open_logo(){
        return "..assets/logo.png"

      }
    }
}
  //console.log(this.event.featuredImage)
</script>

<style lang="scss" scoped>
  .card {
    //background-image: url("../assets/viewer.png");
    height: 200px;
    background-position: center;
    background-size: cover;
    text-align: center;
  }

  .card-content {
    padding-top: 50px;
    position: absolute;
    color: rgb(255, 255, 255);
    background-color:  hsl(204, 69%, 51%);
    background-image: url(../assets/header_texture.png);
    top: 0;
    padding: 10px;
    height: 200px;
    width: 100%;
    span {
      font-size: 18px;
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: 10px;
      right: 0;
    }
    h2 {
      margin-top: 10px;
    }
  }


.card-content:hover {
  background-color: hsl(202, 54%, 65%);
  
}

</style>