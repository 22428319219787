import { createApp } from 'vue'
// import { createAuth0 } from '@auth0/auth0-vue'
import makeStore from './store'
import App from './App.vue'
import router from './router'

const store = makeStore({})
require('@/assets/main.scss');
/*import 'bulma/css/bulma.css'
import 'bulma-switch/src/sass/index.sass';*/
import 'boxicons'

const app = createApp({
   extends: App,
   beforeCreate (e) {
    if (document.location.href.indexOf('/sso-login') > 0) {
      function extractInfoFromURL (url) {
      var split = url.split(/\&|\?|#/)
      var params = []
      var authParams = []
      split.forEach(function(tab) {
        var value = tab.split('=')
        if (value.length > 1) {
          if (['code', 'state', 'session_state', 'error'].indexOf(value[0]) >= 0) {
            authParams[value[0]] = value[1]
          } else {
            params[value[0]] = value[1]
          }
        }
      })
      return {base: split[0], params: params, authParams: authParams}
    }
    var location = extractInfoFromURL(window.location.href)
    if (window.opener) {
      // case window
      window.opener.postMessage(
        {
          code:location.authParams['code'],
          state: location.authParams['state'],
          url: window.location.href
        },
        document.location.origin
      )
      window.close()
    } else if (parent) {
      // case iframe
      parent.postMessage( {
        code:location.authParams['code'],
        state: location.authParams['state'],
        url: window.location.href
      })
      return false
    }
   }
  }
})
app.use(store)
router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !store.getters['user/isAdmin']) {
    return {
      name: 'home',
    }
  }
})
app.use(router).mount('#app')