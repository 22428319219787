<template>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <a href="https://www.ipgp.fr/">
              <img src="../assets/ipgp_logo.png" alt="logo_ipgp_univ" class="imgFooter">
            </a>
            <a href="https://www.ign.fr/">
              <img src="../assets/IGN_logo.png" alt="logo_ign" class="imgFooter">
            </a>
            <a href="https://www.poleterresolide.fr/">
              <img src="../assets/logo-formater.png" alt="logo_formater" class="imgFooter">
            </a>
            <a href="https://cnes.fr/fr">
              <img src="../assets/logo-cnes.png" alt="logo_cnes" class="imgFooter">
            </a>
            
          </div>
          <div class="col-md-6">
            <h5 style="color:gray">Aliedocs ressources:</h5>
            <ul class="no-bullets">
              <li><a href="https://youtube.com/playlist?list=PLI_jA1BV9e3HBwTs3kba4rYkhL6Wo6WlN" style="font-size: 12px;">Tutorial videos</a></li>
              <li><a target="_blank" href="assets/pdf/2023_PosterAliedocsJR23_JBarneoud.pdf" style="font-size: 12px;">Project overview</a></li>
              <li><a href="https://aliedocs.ipgp.fr/#/api" style="font-size: 12px;">API</a></li>
            </ul>
          </div>

          <div class="col-md-6">
            <h5 style="color:gray">Links:</h5>
            <ul class="no-bullets">

              <li><a href="http://loading.u-strasbg.fr/" style="font-size: 12px;">EOST Loading Service</a></li>
            </ul>
          </div>

        </div>
      </div>
    </footer>
</template>


<script>
  export default{
    name: 'FooterLogos'
  }
</script>
  
  
<style>
  footer {
    /*background-image: url("../assets/header_texture.png");*/
    background-color: #f0f0f0;
    padding: 20px 0;
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  
  .col-md-6 a {
    color : blue
  }
  
  .imgFooter {
    height:80px;
    margin : 0px 10px 0px 10px
  }

  ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>
  