import Vue from 'vue'
import {createStore} from 'vuex'
import user from './user'

export default function makeStore(config) {
 return createStore( {
   state () {
   	return config
   },
   modules: {
   	user:user
   }
 })
}