<template>

    <nav class="navbar container" role="navigation" aria-label="main navigation">

    <div class="navbar-brand">
      
      <a href="/">
        <img class="img_nav" src="../assets/logo.png">
      </a>

      <a class="navbar-item" href="/">
        <strong class="is-size-4">ALIEDOCS</strong>
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar" class="navbar-menu">
      <div class="navbar-start">
        <router-link to="/app/viewer" class="navbar-item">Load</router-link>
        <router-link to="/app/computation" class="navbar-item">Deformation</router-link>
        <router-link to="/app/pytrflab" class="navbar-item">Time series analysis</router-link>
        <router-link to="/api" class="navbar-item">API</router-link>

        <router-link to="/documentation" class="navbar-item">Documentation</router-link>

        <!--
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Informations</a>
          <div class="navbar-dropdown">
            <router-link to="/models" class="navbar-item">Loading models</router-link>
            <router-link to="/info_computation" class="navbar-item">Deformation computation</router-link>
          </div>
        </div>
        -->


        
        <router-link to="/about" class="navbar-item">About</router-link>
        <router-link v-if="isAdmin" to="/database" class="navbar-item">Database manager</router-link>
        
      </div>
      <div class="navbar-end" style="vertical-align: middle;">  
      <user/>

      <div style="margin-left:20px">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfbt1H5uqN-ld4TFWakkefkldsdz-vfx9iLQNCAH-s_DOiF2g/viewform?usp=pp_url" 
      target="_blank" title="Website under construction. Help us improve Aliedocs with form feedbacks" style="vertical-align: middle;">
        <img class="img_border" src="../assets/under_construction_notxt.jpg" style="bottom:5; display: inline-block">
        <i style="font-size:0.5em; display: inline-block; margin-left:5px">Website <br>under construction</i>
      </a>
     </div>

      <div class="navbar-item">

      </div>
    </div>
    </div>
  </nav>
</template>
<script>
import User from "./User.vue"
// import { useAuth0, AuthState } from "../utils/useAuth0"
// var api_address = require('../assets/global.json').api_address;
// var api_address = process.env.VUE_APP_api_address;
// console.log("API adress:",api_address);
// console.log(process.env)

  export default {
	  components: {User},
	  computed: {
		  isAdmin () {
			  return this.$store.getters['user/isAdmin']
		  }
	  }
  }
</script>

<style lang="scss" scoped>
  nav {
    margin-top: 0px;
    margin-bottom: 0px;
    a {
      font-weight: bold;
      color: #2c3e50;
      &.router-link-exact-active {
        color: #2b6cce;
      }
    }
  }


  .img_nav {
    width: 50px;
  }


  .img_border{
    width: 50px;
    
  }

  
</style>
